import * as React from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import PlayIcon from '../../images/play-icon-white.svg';
import '../../styles/nutrients/nitrogen.scss';

const Nitrogen = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/phosphorus-plant-proactive-min.png',
    playing: true,
    controls: true,
    width: '100%',
  };

  return (
    <Layout>
      <SEO
        title="Nitrogen Fertilizer Benefits | Crystal Green | Ostara"
        description="Learn Nitrogen's role in plant health and how Crystal Green fertilizers deliver the full package for a success harvest by maximizing nutrient uptake."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Nitrogen Fertilizer"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box w={['100%', '100%', '100%', '46.5%']}>
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Nitrogen</h1>
                  <p style={{ maxWidth: 550 }}>
                    Nitrogen is necessary for all living things to thrive. For
                    plants it is essential in chlorophyll, proteins, and growth
                    regulation. Crystal Green<sup>®</sup> supports your nitrogen
                    program to last through the entire growing season.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/nitrogen-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="nitrogen-body-container">
          <Container className="nitrogen-body-content-1-container" maxW={1400}>
            <Flex className="nitrogen-body-content-1">
              <h3>Why do plants need nitrogen?</h3>
              <p>
                Nitrogen is an essential element in structural, genetic and
                metabolic components of plant cells. It is a critical component
                of amino acids which make up proteins, of the bases of nucleic
                acids that communicate genetic information, and a central
                portion of the chlorophyll molecule.
              </p>
            </Flex>
          </Container>

          <Container
            className="nitrogen-body-content-1-container-mobile nitrogen-mobile"
            maxW={1400}
          >
            <Flex>
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/Ostara-nutrients-nitrogen.jpg"
                alt="Ostara graphic"
              />
            </Flex>
            <Flex className="nitrogen-body-content-1">
              <h3>Why do plants need nitrogen?</h3>
              <p>
                Nitrogen is an essential element in structural, genetic and
                metabolic components of plant cells. It is a critical component
                of amino acids which make up proteins, of the bases of nucleic
                acids that communicate genetic information, and a central
                portion of the chlorophyll molecule.
              </p>
            </Flex>
          </Container>

          <Container
            className="nitrogen-body-content-2-container"
            maxW={1400}
            marginTop={100}
          >
            <Flex className="nitrogen-body-content-2-container--flexbox">
              <div>
                <StaticImage
                  className="exclamation"
                  placeholder="transparent"
                  src="../../images/icon-warning.png"
                  alt="Ostara icon"
                  width={250}
                />
              </div>
              <Flex className="nitrogen-body-content-2">
                <h3>What happens if plants don’t get enough nitrogen?</h3>
              </Flex>
              <Flex className="nitrogen-body-content-2">
                <p>Lower leaves become chlorotic</p>
                <p>Growth slows or stops</p>
                <p>Photosynthesis slows and yields are reduced</p>
              </Flex>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="nitrogen-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex className="nitrogen-body-content-2">
                <h3>Crystal Green’s Nitrogen Advantage</h3>
                <p>
                  The nitrogen in Crystal Green<sup>®</sup> is 100% plant
                  available and releases only when your crop needs it.
                  Typically, excess nitrogen can leach or runoff into waterways,
                  building up excess nitrogen in the water and cause harmful
                  algae blooms.
                </p>
                <br />
                <p>
                  Crystal Green’s Crop Driven Release™ supports crop growth
                  while protecting the environment.
                </p>
                <Link to="/product/crystal-green">Learn how</Link>
              </Flex>
              <div>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/Graphic-blue-min.png"
                  alt="Ostara icons"
                />
              </div>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="nitrogen-data-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>More Data & Insights</h2>
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="nitrogen-data-info"
              spacing={6}
            >
              <ReactPlayer className="module-video" {...config} />
              <Box className="nitrogen-data-content">
                <h5>WEBINAR</h5>
                <h3>Phosphorus: A Plant’s Perspective</h3>

                <p>
                  Learn why phosphorus is essential for plant growth, the
                  agronomy of Crystal Green fertilizer and how growers benefit
                  from this new nutrient technology.
                  <br />
                  <br />
                  Presented by: Dr. Roland Leatherwood, Ostara’s Director of
                  Agronomy & Innovation, and Craig Davidson, President of Taurus
                  Ag.
                </p>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Nitrogen;
